<template>
  <el-container>
    <el-dialog title="报修" :visible.sync="equipmentRepair" width="30%" @close="close">
      <div class="content">
        <div class="input_item">
          <div class="item_vlaue">报修原因</div>
          <div class="item_key">
            <el-input type="textarea" :rows="4" v-model="form.reason" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="equipmentRepair = false">取 消</el-button>
        <el-button type="primary" @click="getWarnSolveInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['equipmentRepair', 'getRepair'],
  data() {
    return {
      form: {
        //报修内容
        reason: '',
        repair: '1',
        //解决方
        resolver: '民政解决'
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeEquipmentRepair')
    },
    async getWarnSolveInfo() {
      this.getRepair(this.form)
      this.equipmentSolve = false
    }
  }
}
</script>
<style lang="less" scoped>
.item_vlaue {
  padding-bottom: 16px;
}
.item_key {
  display: flex;
  align-items: center;
  .name {
    margin-right: 16px;
  }
  .radio {
    display: flex;
  }
}
</style>
